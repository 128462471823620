.result {
    width: 90%;
    margin: 0 auto;
    text-align: center;
}

.result .icon {
    margin-bottom: 24px;
    font-size: 72px;
    line-height: 72px;
}

.result .icon .success {
    color: #52c41a;
}

.result .icon .error {
    color: #f5222d;
}

.result .title {
    margin-bottom: 16px;
    /* color: @heading-color; */
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.result .description {
    margin-bottom: 24px;
    /* color: @text-color-secondary; */
    font-size: 14px;
    line-height: 22px;
}

.result .extra {
    padding: 24px 40px;
    text-align: left;
    background: #fafafa;
}

.result .actions {
    margin-top: 32px;

    button:not(:last-child) {
        margin-right: 8px;
    }
}
