.slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}
.slider.slider-horizontal {
    width: 100%;
    height: 20px;
}
.slider.slider-horizontal .slider-track {
    height: 10px;
    width: 100%;
    margin-top: -5px;
    top: 50%;
    left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
}
.slider.slider-horizontal .slider-tick,
.slider.slider-horizontal .slider-handle {
    margin-left: -10px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-width: 0 10px 10px 10px;
    width: 0;
    height: 0;
    border-bottom-color: #036fa5;
    margin-top: 0;
}
.slider.slider-horizontal .slider-tick-container {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    display: inline-block;
    padding-top: 24px;
    text-align: center;
}
.slider.slider-horizontal.slider-rtl .slider-track {
    left: initial;
    right: 0;
}
.slider.slider-horizontal.slider-rtl .slider-tick,
.slider.slider-horizontal.slider-rtl .slider-handle {
    margin-left: initial;
    margin-right: -10px;
}
.slider.slider-horizontal.slider-rtl .slider-tick-container {
    left: initial;
    right: 0;
}
.slider.slider-vertical {
    height: 210px;
    width: 20px;
}
.slider.slider-vertical .slider-track {
    width: 10px;
    height: 100%;
    left: 25%;
    top: 0;
}
.slider.slider-vertical .slider-selection {
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
    width: 100%;
    left: 0;
    right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
    margin-top: -10px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
    border-width: 10px 0 10px 10px;
    width: 1px;
    height: 1px;
    border-left-color: #036fa5;
    margin-left: 0;
}
.slider.slider-vertical .slider-tick-label-container {
    white-space: nowrap;
}
.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
    padding-left: 4px;
}
.slider.slider-vertical.slider-rtl .slider-track {
    left: initial;
    right: 25%;
}
.slider.slider-vertical.slider-rtl .slider-selection {
    left: initial;
    right: 0;
}
.slider.slider-vertical.slider-rtl .slider-tick.triangle,
.slider.slider-vertical.slider-rtl .slider-handle.triangle {
    border-width: 10px 10px 10px 0;
}
.slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
    padding-left: initial;
    padding-right: 4px;
}
.slider.slider-disabled .slider-handle {
    background-color: #cfcfcf;
    background-image: -moz-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#dfdfdf), to(#bebebe));
    background-image: -webkit-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: -o-linear-gradient(top, #dfdfdf, #bebebe);
    background-image: linear-gradient(to bottom, #dfdfdf, #bebebe);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#DFDFDF', endColorstr='#BEBEBE', GradientType=0);
}
.slider.slider-disabled .slider-track {
    background-color: #e7e7e7;
    background-image: -moz-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#e5e5e5), to(#e9e9e9));
    background-image: -webkit-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: -o-linear-gradient(top, #e5e5e5, #e9e9e9);
    background-image: linear-gradient(to bottom, #e5e5e5, #e9e9e9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#E5E5E5', endColorstr='#E9E9E9', GradientType=0);
    cursor: not-allowed;
}
.slider input {
    display: none;
}
.slider .tooltip-inner {
    white-space: nowrap;
    max-width: none;
}
.slider .tooltip {
    pointer-events: none;
}
.slider .tooltip.top {
    margin-top: -36px;
}
.slider .tooltip-inner {
    white-space: nowrap;
    max-width: none;
}
.slider .hide {
    display: none;
}
.slider-track {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#F9F9F9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    cursor: pointer;
}
.slider-selection {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f9f9f9), to(#f5f5f5));
    background-image: -webkit-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: -o-linear-gradient(top, #f9f9f9, #f5f5f5);
    background-image: linear-gradient(to bottom, #f9f9f9, #f5f5f5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F9F9F9', endColorstr='#f5f5f5', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
}
.slider-selection.tick-slider-selection {
    background-color: #46c1fe;
    background-image: -moz-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#52c5ff), to(#3abcfd));
    background-image: -webkit-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -o-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: linear-gradient(to bottom, #52c5ff, #3abcfd);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd', GradientType=0);
}
.slider-track-low,
.slider-track-high {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    position: absolute;
    background: transparent;
}
.slider-handle {
    background-color: #0478b2;
    background-image: -moz-linear-gradient(top, #0480be, #036fa5);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0480be), to(#036fa5));
    background-image: -webkit-linear-gradient(top, #0480be, #036fa5);
    background-image: -o-linear-gradient(top, #0480be, #036fa5);
    background-image: linear-gradient(to bottom, #0480be, #036fa5);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0480BE', endColorstr='#036fa5', GradientType=0);
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #0480be;
    border: 0px solid transparent;
}
.slider-handle:hover {
    cursor: pointer;
}
.slider-handle.round {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}
.slider-handle.triangle {
    background: transparent none;
}
.slider-handle.custom {
    background: transparent none;
}
.slider-handle.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
}
.slider-tick {
    background-color: #f7f7f7;
    background-image: -moz-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f5f5f5), to(#f9f9f9));
    background-image: -webkit-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: -o-linear-gradient(top, #f5f5f5, #f9f9f9);
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#F9F9F9', GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    filter: none;
    opacity: 0.8;
    border: 0px solid transparent;
}
.slider-tick.round {
    border-radius: 50%;
}
.slider-tick.triangle {
    background: transparent none;
}
.slider-tick.custom {
    background: transparent none;
}
.slider-tick.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
}
.slider-tick.in-selection {
    background-color: #46c1fe;
    background-image: -moz-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#52c5ff), to(#3abcfd));
    background-image: -webkit-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: -o-linear-gradient(top, #52c5ff, #3abcfd);
    background-image: linear-gradient(to bottom, #52c5ff, #3abcfd);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#52c5ff', endColorstr='#3abcfd', GradientType=0);
    opacity: 1;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -8px;
    position: absolute;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #aeaeae;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -8px;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -1px;
    border-bottom-color: #aeaeae;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -8px;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -1px;
    border-top-color: #aeaeae;
}
.react-datepicker-wrapper {
    display: inline-block;
}
.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}
.react-datepicker--time-only .react-datepicker__triangle {
    left: 35px;
}
.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}
.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}
.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}
.react-datepicker__triangle {
    position: absolute;
    left: 50px;
}
.react-datepicker-popper {
    z-index: 1;
}
.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 10px;
}
.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 10px;
}
.react-datepicker-popper[data-placement^="right"] {
    margin-left: 8px;
}
.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 42px;
}
.react-datepicker-popper[data-placement^="left"] {
    margin-right: 8px;
}
.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 42px;
    right: auto;
}
.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    padding-top: 8px;
    position: relative;
}
.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px;
}
.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 2px;
}
.react-datepicker__current-month,
.react-datepicker-time__header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: 0.944rem;
}
.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.react-datepicker__navigation {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    width: 0;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
}
.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #ccc;
}
.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}
.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}
.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #ccc;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 80px;
}
.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}
.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}
.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
}
.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}
.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
}
.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}
.react-datepicker__month-container {
    float: left;
}
.react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
}
.react-datepicker__time-container {
    float: right;
    border-left: 1px solid #aeaeae;
    width: 70px;
}
.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -72px;
    top: 0;
}
.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 70px;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + (1.7rem / 2));
    overflow-y: scroll;
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
    box-sizing: content-box;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 30px;
    padding: 5px 10px;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}
.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}
.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}
.react-datepicker__day {
    cursor: pointer;
}
.react-datepicker__day:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--today {
    font-weight: bold;
}
.react-datepicker__day--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}
.react-datepicker__day--highlighted:hover {
    background-color: #32be3f;
}
.react-datepicker__day--highlighted-custom-1 {
    color: magenta;
}
.react-datepicker__day--highlighted-custom-2 {
    color: green;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}
.react-datepicker__day--keyboard-selected:hover {
    background-color: #1d5d90;
}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(33, 107, 165, 0.5);
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #f0f0f0;
    color: #000;
}
.react-datepicker__day--disabled {
    cursor: default;
    color: #ccc;
}
.react-datepicker__day--disabled:hover {
    background-color: transparent;
}
.react-datepicker__input-container {
    position: relative;
    display: inline-block;
}
.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 1px solid transparent;
    border-radius: 0.3rem;
}
.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: 0.45rem;
}
.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 1px solid #aeaeae;
}
.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}
.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll;
}
.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}
.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 15px;
}
.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0;
    padding: 0;
    vertical-align: middle;
    position: absolute;
    height: 16px;
    width: 16px;
    top: 25%;
    right: 7px;
}
.react-datepicker__close-icon::after {
    background-color: #216ba5;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 0px;
    text-align: center;
}
.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 1px solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
}
.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
    width: 3rem;
    line-height: 3rem;
}
@media (max-width: 400px), (max-height: 550px) {
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__time-name {
        width: 2rem;
        line-height: 2rem;
    }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
    font-size: 1.44rem;
}
.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}
.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}
.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}
.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}
.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}
.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}
.rdw-option-wrapper {
    border: 1px solid #f1f1f1;
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-option-wrapper:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-option-wrapper img {
    width: 13px;
}
.rdw-option-disabled {
    opacity: 0.3;
    cursor: default;
}
.rdw-dropdown-wrapper {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    background: white;
}
.rdw-dropdown-wrapper:focus {
    outline: none;
}
.rdw-dropdown-wrapper:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
    background-color: #ffffff;
}
.rdw-dropdown-wrapper:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-dropdown-carettoopen {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.rdw-dropdown-carettoclose {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.rdw-dropdown-selectedtext {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
}
.rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #f1f1f1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
}
.rdw-dropdown-optionwrapper:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
    background-color: #ffffff;
}
.rdw-dropdownoption-default {
    min-height: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
    background: #f1f1f1;
}
.rdw-dropdownoption-active {
    background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
    opacity: 0.3;
    cursor: default;
}
.rdw-inline-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 6px;
}
.rdw-inline-dropdown {
    width: 50px;
}
.rdw-inline-dropdownoption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-block-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-block-dropdown {
    width: 110px;
}
.rdw-fontsize-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-fontsize-dropdown {
    min-width: 40px;
}
.rdw-fontsize-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-fontfamily-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-fontfamily-dropdown {
    width: 115px;
}
.rdw-fontfamily-placeholder {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rdw-fontfamily-optionwrapper {
    width: 140px;
}
.rdw-list-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-list-dropdown {
    width: 50px;
    z-index: 90;
}
.rdw-list-dropdownOption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-text-align-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-text-align-dropdown {
    width: 50px;
    z-index: 90;
}
.rdw-text-align-dropdownOption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-right-aligned-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.rdw-left-aligned-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.rdw-center-aligned-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-justify-aligned-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.rdw-colorpicker-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-colorpicker-modal {
    position: absolute;
    top: 35px;
    right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 200px;
    height: 200px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-colorpicker-modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 5px;
}
.rdw-colorpicker-modal-style-label {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
}
.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #0a66b7;
}
.rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 170px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #f1f1f1;
}
.rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #bfbdbd inset;
}
.rdw-colorpicker-option:hover {
    box-shadow: 1px 2px 1px #bfbdbd;
}
.rdw-colorpicker-option:active {
    box-shadow: -1px -2px 1px #bfbdbd;
}
.rdw-colorpicker-option-active {
    box-shadow: 0px 0px 2px 2px #bfbdbd;
}
.rdw-link-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-link-dropdown {
    width: 50px;
}
.rdw-link-dropdownOption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-link-dropdownPlaceholder {
    margin-left: 8px;
}
.rdw-link-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-link-modal-label {
    font-size: 15px;
}
.rdw-link-modal-input {
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;
}
.rdw-link-modal-input:focus {
    outline: none;
}
.rdw-link-modal-buttonsection {
    margin: 0 auto;
}
.rdw-link-modal-btn {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-link-modal-btn:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-link-modal-btn:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-link-modal-btn:focus {
    outline: none !important;
}
.rdw-link-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-link-dropdownoption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-history-dropdown {
    width: 50px;
}
.rdw-embedded-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-embedded-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-embedded-modal-header {
    font-size: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rdw-embedded-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.rdw-embedded-modal-header-label {
    width: 95px;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
    background: #6eb8d4;
    border-bottom: 2px solid #0a66b7;
}
.rdw-embedded-modal-link-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.rdw-embedded-modal-link-input {
    width: 95%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-embedded-modal-link-input:focus {
    outline: none;
}
.rdw-embedded-modal-btn-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-embedded-modal-btn {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-embedded-modal-btn:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-embedded-modal-btn:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-embedded-modal-btn:focus {
    outline: none !important;
}
.rdw-embedded-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-embedded-modal-size {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 5px 0 10px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.rdw-embedded-modal-size-input {
    width: 45%;
    height: 20px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-embedded-modal-size-input:focus {
    outline: none;
}
.rdw-emoji-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-emoji-modal {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-emoji-icon {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.rdw-spinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.rdw-spinner > div {
    width: 12px;
    height: 12px;
    background-color: #333;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.rdw-spinner .rdw-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.rdw-spinner .rdw-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}
@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
.rdw-image-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-image-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 235px;
    height: 200px;
    border: 1px solid #f1f1f1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #bfbdbd;
}
.rdw-image-modal-header {
    font-size: 15px;
    margin: 10px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rdw-image-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.rdw-image-modal-header-label {
    width: 80px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
}
.rdw-image-modal-header-label-highlighted {
    background: #6eb8d4;
    border-bottom: 2px solid #0a66b7;
}
.rdw-image-modal-upload-option {
    height: 65px;
    width: 100%;
    color: gray;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: none;
    font-size: 15px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #f1f1f1;
    outline: 2px dashed gray;
    outline-offset: -10px;
    margin: 10px 0;
}
.rdw-image-modal-upload-option-highlighted {
    outline: 2px dashed #0a66b7;
}
.rdw-image-modal-upload-option-label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.rdw-image-modal-upload-option-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.rdw-image-modal-url-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.rdw-image-modal-url-input {
    width: 95%;
    height: 35px;
    margin: 25px 0 5px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-image-modal-btn-section {
    margin: 10px auto 0;
}
.rdw-image-modal-url-input:focus {
    outline: none;
}
.rdw-image-modal-btn {
    margin: 0 5px;
    width: 75px;
    height: 30px;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-image-modal-btn:hover {
    box-shadow: 1px 1px 0px #bfbdbd;
}
.rdw-image-modal-btn:active {
    box-shadow: 1px 1px 0px #bfbdbd inset;
}
.rdw-image-modal-btn:focus {
    outline: none !important;
}
.rdw-image-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-image-modal-spinner {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
.rdw-remove-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-history-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-history-dropdownoption {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-history-dropdown {
    width: 50px;
}
.rdw-link-decorator-wrapper {
    position: relative;
}
.rdw-link-decorator-icon {
    position: absolute;
    left: 40%;
    top: 0;
    cursor: pointer;
    background-color: white;
}
.rdw-mention {
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}
.rdw-mention-link {
    text-decoration: none;
}
.rdw-suggestion-wrapper {
    position: relative;
}
.rdw-suggestion-dropdown {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #f1f1f1;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
}
.rdw-suggestion-option {
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
}
.rdw-suggestion-option-active {
    background-color: #f1f1f1;
}
.rdw-image-alignment-options-popup {
    position: absolute;
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 5px 2px;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    width: 105px;
    cursor: pointer;
    z-index: 100;
}
.rdw-alignment-option-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.rdw-image-alignment-option {
    height: 15px;
    width: 15px;
    min-width: 15px;
}
.rdw-image-alignment {
    position: relative;
}
.rdw-image-imagewrapper {
    position: relative;
}
.rdw-image-center {
    float: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.rdw-image-left {
    float: left;
}
.rdw-image-right {
    float: right;
}
.rdw-editor-main {
    height: 150px;
    width: 100%;
    overflow: auto;
    box-sizing: content-box;
    background: #fff;
}
.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
    background: white;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.public-DraftStyleDefault-block {
    margin: 1em 0;
}
.rdw-editor-wrapper:focus {
    outline: none;
}
.DraftEditor-root {
    padding: 0 10px;
}
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
    height: inherit;
    text-align: initial;
}
.public-DraftEditor-content[contenteditable=true] {
    -webkit-user-modify: read-write-plaintext-only;
}
.DraftEditor-root {
    position: relative;
}
.DraftEditor-editorContainer {
    background-color: rgba(255, 255, 255, 0);
    border-left: .1px solid transparent;
    position: relative;
    z-index: 1;
}
.public-DraftEditor-block {
    position: relative;
}
.DraftEditor-alignLeft .public-DraftStyleDefault-block {
    text-align: left;
}
.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
    left: 0;
    text-align: left;
}
.DraftEditor-alignCenter .public-DraftStyleDefault-block {
    text-align: center;
}
.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
    margin: 0 auto;
    text-align: center;
    width: 100%;
}
.DraftEditor-alignRight .public-DraftStyleDefault-block {
    text-align: right;
}
.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
    right: 0;
    text-align: right;
}
.public-DraftEditorPlaceholder-root {
    color: #9197a3;
    position: absolute;
    z-index: 0;
}
.public-DraftEditorPlaceholder-hasFocus {
    color: #bdc1c9;
}
.DraftEditorPlaceholder-hidden {
    display: none;
}
.public-DraftStyleDefault-block {
    position: relative;
    white-space: pre-wrap;
}
.public-DraftStyleDefault-ltr {
    direction: ltr;
    text-align: left;
}
.public-DraftStyleDefault-rtl {
    direction: rtl;
    text-align: right;
}
.public-DraftStyleDefault-listLTR {
    direction: ltr;
}
.public-DraftStyleDefault-listRTL {
    direction: rtl;
}
.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
    margin: 16px 0;
    padding: 0;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
    margin-left: 1.5em;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
    margin-right: 1.5em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
    margin-left: 3em;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
    margin-right: 3em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
    margin-left: 4.5em;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
    margin-right: 4.5em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
    margin-left: 6em;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
    margin-right: 6em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
    margin-left: 7.5em;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
    margin-right: 7.5em;
}
.public-DraftStyleDefault-unorderedListItem {
    list-style-type: square;
    position: relative;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
    list-style-type: disc;
}
.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
    list-style-type: circle;
}
.public-DraftStyleDefault-orderedListItem {
    list-style-type: none;
    position: relative;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    left: -36px;
    position: absolute;
    text-align: right;
    width: 30px;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
    position: absolute;
    right: -36px;
    text-align: left;
    width: 30px;
}
.public-DraftStyleDefault-orderedListItem:before {
    content: counter(ol0) ". ";
    counter-increment: ol0;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
    content: counter(ol1) ". ";
    counter-increment: ol1;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
    content: counter(ol2) ". ";
    counter-increment: ol2;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
    content: counter(ol3) ". ";
    counter-increment: ol3;
}
.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
    content: counter(ol4) ". ";
    counter-increment: ol4;
}
.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
}
.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
}
.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2;
}
.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3;
}
.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4;
}
.react-form-builder .react-form-builder-preview {
    position: relative;
    width: 70%;
    border: 1px solid #ddd;
    background: #fafafa;
    padding: 10px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    min-height: 750px;
}
.react-form-builder .react-form-builder-preview .preview-page-break {
    padding: 5px 0;
    border-top: 2px dotted #ccc;
    border-bottom: 2px dotted #ccc;
    background: #eee;
    text-align: center;
    width: 100%;
    font-weight: bold;
    margin: 5px 0;
    background: repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px);
}
.react-form-builder .react-form-builder-preview label {
    font-weight: normal;
}
.react-form-builder .react-form-builder-preview .bold {
    font-weight: bold;
}
.react-form-builder .react-form-builder-preview .italic {
    font-style: italic;
}
.react-form-builder .react-form-builder-preview .no-image {
    background: #eee;
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    text-align: center;
    padding-top: 35px;
}
.react-form-builder .react-form-builder-preview .option-inline {
    display: inline-block !important;
    margin-right: 10px !important;
}
.react-form-builder .react-form-builder-preview .form-label {
    display: block !important;
}
.react-form-builder .react-form-builder-preview .edit-form {
    position: fixed;
    background: #fafafa;
    padding: 30px;
    border-left: 0;
    box-shadow: none;
    top: 0;
    left: -1000px;
    height: 100%;
    width: 715px;
    transition: .5s;
    overflow-y: auto;
}
.react-form-builder .react-form-builder-preview .edit-form h4,
.react-form-builder .react-form-builder-preview .edit-form .dismiss-edit {
    margin-bottom: 30px;
}
.react-form-builder .react-form-builder-preview .edit-form .dismiss-edit {
    cursor: pointer;
    margin-top: 10px;
    font-size: 125%;
}
.react-form-builder .react-form-builder-preview .edit-form textarea {
    width: 100%;
}
.react-form-builder .react-form-builder-preview .edit-form .form-group .form-group-range label {
    display: block;
}
.react-form-builder .react-form-builder-preview .edit-form .form-group .form-group-range input {
    display: inline-block;
    width: 21%;
    margin-left: 0;
}
.react-form-builder .react-form-builder-preview .edit-form .form-group .form-group-range input:last-of-type {
    width: 78%;
    margin-left: 1%;
}
.react-form-builder .react-form-builder-preview .edit-form .form-group .form-group-range input:first-of-type {
    width: 21%;
    margin-left: 0;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list {
    margin-bottom: 15px;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li {
    margin-top: 10px;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li input {
    margin-right: 10px;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li input.form-control {
    width: 84.26%;
    float: left;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li input.form-control[type="checkbox"] {
    border: none;
    box-shadow: none;
    margin-top: 0;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li button {
    float: left;
    margin-right: 5px;
}
.react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li .dynamic-options-actions-buttons {
    margin-left: 20px;
}
.react-form-builder .react-form-builder-preview .Sortable {
    transition: opacity .25s ease-in;
    position: relative;
    cursor: move;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item {
    padding: 10px 20px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem {
    position: relative;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group {
    pointer-events: none;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group label,
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group select,
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group input,
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group a {
    cursor: move;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group input[type='date'] {
    height: 42px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group .react-star-rating {
    display: block;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group .checkbox-label,
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group .radio-label {
    font-weight: normal;
    display: block;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem .form-group .label-required {
    margin-left: 5px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.SortableItem.is-dragging {
    position: absolute;
    z-index: 1688;
    border: 2px #ccc dashed;
    background: #fff;
    cursor: move;
    padding: 10px 20px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item.is-placeholder {
    display: block;
    z-index: auto;
    opacity: .4;
    border: 2px #ccc dashed;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header {
    opacity: 0;
    z-index: 100;
    position: relative;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .label {
    position: absolute;
    left: 20px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
    position: absolute;
    right: 20px;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item:hover {
    box-shadow: inset 0 0 5px #ddd;
    background: #fff;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item:hover select,
.react-form-builder .react-form-builder-preview .Sortable .rfb-item:hover input {
    cursor: move;
    pointer-events: none;
}
.react-form-builder .react-form-builder-preview .Sortable .rfb-item:hover .toolbar-header {
    opacity: 1;
}
.react-form-builder .react-form-builder-preview.is-editing .edit-form {
    z-index: 2000;
    border-right: 1px solid #ddd;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    left: 0;
    transition: .5s;
}
.react-form-builder .react-form-builder-toolbar {
    width: 250px;
    background: #fff;
    margin-top: -30px;
}
.react-form-builder .react-form-builder-toolbar h4 {
    margin-top: 0;
    text-align: center;
}
.react-form-builder .react-form-builder-toolbar .form-group {
    padding: 10px;
    border: 1px dashed #ddd;
}
.react-form-builder .react-form-builder-toolbar ul {
    padding: 0;
}
.react-form-builder .react-form-builder-toolbar ul li {
    cursor: pointer;
    list-style: none;
    margin: 5px;
    padding: 10px;
    border: 1px dashed #ddd;
}
.react-form-builder .react-form-builder-toolbar ul li i {
    margin: 0 15px 0 10px;
}
.image-upload-container {
    position: relative;
}
.image-upload-container .image-upload {
    position: relative;
    opacity: 0;
    z-index: 2;
    height: 50px;
}
.image-upload-container .image-upload-control {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}
.image-upload-preview {
    border: 4px solid #fff;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}
.btn-image-clear {
    margin: 15px 0;
    padding: 15px;
}
@media (max-width: 768px) {
    .react-form-builder {
        position: relative;
    }
    .react-form-builder .react-form-builder-preview {
        width: 100%;
    }
    .react-form-builder .react-form-builder-preview .edit-form {
        width: 100%;
        overflow: scroll;
    }
    .react-form-builder .react-form-builder-preview .edit-form .btn {
        margin-top: 10px;
    }
    .react-form-builder .react-form-builder-preview .edit-form .dynamic-option-list ul li input.form-control {
        width: 100%;
    }
    .react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .toolbar-header-buttons {
        right: -15px;
    }
    .react-form-builder .react-form-builder-preview .Sortable .rfb-item .toolbar-header .toolbar-header-buttons .btn {
        margin-right: 5px;
        border-radius: 50%;
        padding: 7px 0px 0 3px;
        width: 35px;
        height: 35px;
    }
    .react-form-builder .react-form-builder-toolbar {
        width: 100%;
        position: fixed;
        z-index: 900;
        bottom: -320px;
        -webkit-transition: transform 0.5s;
        transition: transform 0.5s;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        right: 0;
        border-top: 2px solid #ccc;
    }
    .react-form-builder .react-form-builder-toolbar h4 {
        padding: 10px;
        background: #7a0019;
        color: #fff;
    }
    .react-form-builder .react-form-builder-toolbar ul {
        height: 300px;
        overflow-x: scroll;
    }
    .react-form-builder .active-toolbar {
        -webkit-transform: translate(0, -300px);
        transform: translate(0, -300px);
    }
    .m-signature-pad {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        min-width: 250px;
        min-height: 140px;
        margin: 0;
    }
}
.visible_marks {
    display: block;
    width: 100%;
    padding: 0 4px;
}
.visible_marks label {
    text-align: center;
    display: inline-block;
    padding: 0;
    margin: 0;
    font-weight: normal;
}
.visible_marks label:first-of-type {
    text-align: left;
}
.visible_marks label:last-of-type {
    text-align: right;
}
.m-signature-pad {
    font-size: 10px;
    width: auto;
}
.m-signature-pad .m-signature-pad--body {
    width: auto;
    height: 200px;
    border: 1px solid #e8e8e8;
    background-color: #fff;
    height: 200px;
    border-radius: 4px;
}
.m-signature-pad .m-signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02) inset;
}
.m-signature-pad .m-signature-pad--footer {
    position: relative;
    margin-top: 10px;
}
.form-place-holder {
    margin: 10px;
    border: 2px dashed #bbb;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 25px;
    text-align: center;
    font: 20pt bold, "Vollkorn";
    color: #bbb;
}
input[type="date"]:before {
    content: attr(placeholder);
    color: #aaa;
    margin-right: 0.5em;
}
input[type="date"] {
    width: 200px;
}
.validation-error {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 99999999;
}
.validation-error ul {
    width: auto;
}
.validation-error .dismiss-modal-button {
    margin-top: 10px;
}
.react-form-builder-form {
    position: relative;
}
.react-form-builder-form .rfb-item.alwaysbreak {
    page-break-before: always;
}
.react-form-builder-form .rfb-item.nobreak:before {
    clear: both;
}
.react-form-builder-form .rfb-item.nobreak {
    page-break-inside: avoid;
}
.react-form-builder-form .rfb-item {
    padding: 10px 0;
    position: relative;
}
.react-form-builder-form .rfb-item label {
    font-weight: normal;
}
.react-form-builder-form .rfb-item .bold {
    font-weight: bold;
}
.react-form-builder-form .rfb-item .italic {
    font-style: italic;
}
.react-form-builder-form .rfb-item .form-label {
    display: block !important;
}
.react-form-builder-form .rfb-item .form-group .option-inline {
    display: inline-block !important;
    margin-right: 10px;
}
.react-form-builder-form .rfb-item .form-group a {
    cursor: pointer;
}
.react-form-builder-form .rfb-item .form-group input[type='date'] {
    height: 42px;
}
.react-form-builder-form .rfb-item .form-group .m-signature-pad {
    position: relative;
    width: auto;
}
.react-form-builder-form .rfb-item .form-group .react-star-rating {
    display: block;
}
.react-form-builder-form .rfb-item .form-group .checkbox-label,
.react-form-builder-form .rfb-item .form-group .radio-label {
    font-weight: normal;
    display: block;
}
.react-form-builder-form .rfb-item .form-group .label-required {
    margin-left: 5px;
}
