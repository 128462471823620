.ant-form-item label {
    overflow-wrap: break-word;
    height: fit-content;
}
.notifications-tr {
    position: absolute !important;
    right: 10px !important;
}
.preview-container {
    overflow-y: scroll;
    height: 80vh;
    border-bottom: 1px solid gray;
    border-top: 1px solid gray;
}
.ant-form-item label {
    white-space: normal;
    padding: 0px 10px 0px 0px;
    line-height: 1.5;
    margin: 0 8px 8px 2px;
}
.ant-form-item label::after {
    margin: 0;
}
label.ant-radio-button-wrapper {
    padding: 5px 10px;
}
.ant-row.filter-row {
    padding: 10px 0 0;
}
input.ant-input[placeholder="Enter the details of your selected topic"] {
    width: calc(50% - 11px);
    float: left;
}
.ant-form-item label[for="basic-form_claire_virtula_assistant_templates"]::after {
    display: none !important;
    margin: 0;
}
