.ant-card {
    margin: 2px;
    border: 2px solid skyblue;
}
.ant-row {
    align-items: center;
    justify-content: center;
}
.ant-card-head {
    background-color: #dbf4ff;
}
.ant-card-body {
    padding: 10px;
}
.status-icon {
    font-size: 26px;
}
table.budget-spend {
    overflow-x: scroll;
    display: block;
}
table.budget-spend td,
th {
    padding: 10px 2px;
    text-align: center;
}
table.budget-spend td:first-child {
    white-space: nowrap;
}
table.budget-spend td span {
    font-size: small;
}
.anticon {
    font-size: 20px;
}
.budget-row {
    border-bottom: 1px solid;
}
.budget-row:last-child {
    border-bottom: none;
}
.add-budget-button {
    position: relative;
}
/* charts */
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-bar {
    stroke: #7141f7 !important;
}
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-bar {
    stroke: #d70206 !important;
}
.ct-series-c .ct-line,
.ct-series-c .ct-point,
.ct-series-c .ct-bar {
    stroke: #b4ceff !important;
}
.ct-series-d .ct-line,
.ct-series-d .ct-point,
.ct-series-d .ct-bar {
    stroke: green !important;
}
.ct-series-e .ct-line,
.ct-series-e .ct-point,
.ct-series-e .ct-bar {
    stroke: #ff00bf !important;
}
.ct-chart {
    height: 350px;
    margin: 0 0 5px 0;
}
ul.ct-legend {
    font-weight: 600;
    list-style-type: none;
    display: flex;
    text-align: center;
    width: 100%;
    margin: 0;
}
li.ct-series-0 {
    color: #7141f7;
    margin: 0 10px;
    cursor: pointer;
}
li.ct-series-1 {
    color: #d70206;
    margin: 0 10px;
    cursor: pointer;
}
li.ct-series-2 {
    color: #b4ceff;
    margin: 0 10px;
    cursor: pointer;
}
li.ct-series-3 {
    color: green;
    margin: 0 10px;
    cursor: pointer;
}
li.ct-series-4 {
    color: #ff00bf;
    margin: 0 10px;
    cursor: pointer;
}

.ant-tooltip-inner li.ant-list-item {
    color: white;
}

.no-data {
    margin-top: 100px;
    text-align: center;
}

.no-data-row {
    margin-bottom: 10px;
    margin-top: 10px;
}

.ant-col-24 {
    margin: 2px;
}

.card-div-outer {
    display: flex;
    justify-content: space-between;
}

.card-div-inner-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-right: 5px;
    flex: 2;
}

.card-div-inner-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
}

.update-export-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blinking-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    max-width: 150px;
    animation: blinker 3s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.margin-bottom {
    margin-bottom: 10px;
}

.custom-icon-wrapper {
    position: relative;
    font-size: 30px;
    width: 100px;
    height: 24px;
    margin: 0 5px;
    cursor: pointer;
}

.custom-icon-wrapper.selected {
    opacity: 1;
}

.custom-icon-wrapper.notselected {
    opacity: 0.6;
}

img.odoo-icon {
    overflow: hidden;
    top: 0px;
    position: absolute;
    left: -10px;
    height: 24px;
}
.custom-icon-subsign {
    font-size: 11px;
    position: absolute;
    bottom: -7px;
}
.log-modal {
    width: 50%;
    height: 50vh;
}
.log-modal .ant-modal-body {
    overflow-y: scroll;
    padding: 15px 24px;
}
.log-line p {
    font-size: small;
    padding: 0px;
    margin: 0px;
}
.log-line p span {
    color: #6d6fff;
}

.notice-edit-field {
    background: #fff7e7;
    min-height: 30px;
    max-height: 400px;
    overflow-y: scroll;
    font-size: small;
}
.notice-edit-field-textarea {
    min-height: 30px;
    max-height: 400px;
    font-size: small;
}

.planned-spend {
    color: #8e8e8e;
    background: #c7ffff;
}

.notification-cell {
    padding: 1px!important;
    font-size: 10px!important;
}
