.banner-id {
    color: gray;
}
.brand-name-good a {
    color: blue;
    font-weight: 600;
}
.brand-name a {
    color: blue;
    font-weight: 300;
}
.icon-card {
    display: flex;
    flex-flow: wrap;
    width: 100%;
    min-height: 150px;
    background: lavender;
    border-radius: 10px;
    padding: 15px;
}

.icon-card > span {
    margin: 0px auto 0px auto;
    display: inline;
    font-size: large;
    position: relative;
}

.icon-card > i {
    margin: 15px auto 0px auto;
    width: 100%;
    font-size: 52px;
}

/* //  ant-row, data-label change bottom indent */

.ant-row {
    padding: 0 10px;
    margin: 0;
}

/* //  Timetable change in forms-approval */

.ant-row .ant-form-item-control {
    margin: 0 -10px;
}

.ant-row .ant-form-item-control .ant-form-item-children .timetable .ant-form-item {
    margin: 0 8px 10px 0;
}

.ant-row .ant-form-item-control .ant-form-item-children .timetable .ant-form-item .ant-col {
    line-height: 0;
    margin: 0;
}

.ant-row .ant-form-item-control .ant-form-item-children .timetable .ant-form-item .ant-form-item-label {
    transform: translateX(-10px);
}

.ant-row .ant-form-item-control .ant-form-item-children .ant-row:first-child .ant-col {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 700;
}

.ant-spin-nested-loading .ant-spin-container form {
    margin: 0 0 25px;
}

div[data-label] {
    padding: 10px;
    margin: 0 0 24px;
}

.timetable {
    margin: 0 0 24px;
}

span.date {
    font-weight: 600;
}
span.date,
span.name {
    font-weight: 600;
}
.good {
    color: white;
    background: rgb(0, 168, 0);
}

.bad {
    color: white;
    background: rgb(168, 0, 0);
}
.banner-card,
.banner-card-main {
    border-radius: 10px;
    padding: 2px;
    margin: 5px;
    background: white;
    border: 2px solid #999;
    overflow: hidden;
    display: inline-grid;
}

.banner-image,
.banner-status {
    margin: auto;
}
.banner-status * {
    margin: 1px 1px 0 0;
}
.banner-card-main img {
    width: 300px;
    height: 250px;
    object-fit: scale-down;
}
.banner-card-main .status-icon {
    font-size: 26px;
}
.banner-card-main .ant-tag {
    font-size: 16px;
    vertical-align: super;
    top: 2px;
    position: relative;
}
.banner-card img {
    width: 150px;
    height: 125px;
    object-fit: scale-down;
}
.banner-card .status-icon {
    font-size: 16px;
}

.banner-status div {
    display: inline;
    font-size: large;
}
.banner-card .ant-tag {
    font-size: 10px;
    padding: 2px;
    line-height: 1.2;
    top: -3px;
    position: relative;
    min-width: 10px;
}

.custom-icon-wrapper {
    position: relative;
    font-size: 30px;
    width: 30px;
    height: 30px;
    margin: 0 5px;
}
.custom-icon {
    margin: 0 5px;
}
.custom-icon-subsign {
    position: absolute;
    font-size: 5px;
    left: 10px;
}
.warning-icon {
    font-size: 30px;
    position: absolute;
    color: white;
    background: RGBa(255, 0, 0, 0.8);
    border-radius: 15px;
}

/* //  Added bottom and top indent */

.holder-indent {
    padding: 5px 0;
}

/* //  Pagination indent bottom in forms-approval-list page */

.pagination-list {
    margin: 0 0 15px;
    padding: 0 10px;
}

/* //  Canged calendar-picker and elect-selection__rendered */

.indent-date-review {
    margin-top: 10px;
    margin-bottom: 10px;
}

.ant-calendar-picker {
    min-width: 120px;
}

.ant-select-selection__rendered {
    min-width: 75px;
}
