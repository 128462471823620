.button-bar {
    min-width: 200px;

}
.last-update {
    text-align: center;
    color: gray;
    font-weight: 600;
}
.paltform-section{
    background-color: #e9f1ff;
    border-top: solid 1px black;
}
.button-container{
    margin: 20px auto;
    text-align: center;
}
.button-container button{
    margin: 5px 2px;
    border-radius: 10px;
    color: black;
    font-weight: 600;
    background: rgb(180, 253, 177);
}