.ant-upload.ant-upload-drag {
    height: 200px;
}

.ant-input-affix-wrapper .ant-input {
    min-height: unset;
}

.ant-checkbox + span {
    text-transform: none;
}

.field-tooltip-icon { 
    color: rgba(0,0,0,.45); 
}

.editable-text-loading-icon {
    margin-left: 12px;
}
